import { domain } from '@/siteInfo'
import { fetch } from '@/utils/fetchs.js'

// 设备绑定
export const equipmentBind = (params = {}) => fetch(domain + '/admin/v1/equipment/equipmentBind', params)
//绑定内容编辑(在设备清单里面的编辑)
export const equipmentBindEdit = (params = {}) => fetch(domain + '/admin/v1/equipment/equipmentBindEdit', params)
// 设备分红设置
export const shareMoneyConfig = (params = {}) => fetch(domain + '/admin/v1/equipment/shareMoneyConfig', params)
// 设备分红详情
export const shareMoneyConfigInfo = (params = {}) => fetch(domain + '/admin/v1/equipment/shareMoneyConfigInfo', params)
//设备二维码生成/更新
export const createQRCode = (params = {}) => fetch(domain + '/admin/v1/equipment/createQRCode', params)

//设备声音WiFi配置
export const equipmentConfig = (params = {}) => fetch(domain + '/admin/v1/equipment/equipmentConfig', params)
//设备声音WiFi配置详情
export const equipmentConfigInfo = (params = {}) => fetch(domain + '/admin/v1/equipment/equipmentConfigInfo', params)
//设备租用
export const equipmentRent = (params = {}) => fetch(domain + '/admin/v1/equipment/equipmentRent', params)
// 设备编辑详情
export const equipmentBindInfo = (params = {}) => fetch(domain + '/admin/v1/equipment/equipmentBindInfo', params)

// 设备编辑详情
export const templateSelect = (params = {}) => fetch(domain + '/admin/v1/template/select', params)
